<!-- eslint-disable vue/no-v-html -->

<template>
  <section
    class="relative overflow-hidden bg-black px-0 md:min-h-[390px] md:px-6 md:py-10"
  >
    <picture class="size-full">
      <template v-for="image in block.responsiveHeroImage" :key="image.id">
        <source
          v-if="image?.typeHandle === 'BlockMobileImage' && image?.image"
          media="(max-width: 768px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockTabletImage' && image?.image"
          media="(max-width: 992px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          media="(min-width: 993px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          media="(min-width: 993px)"
          :srcset="image?.fallback?.[0]?.url"
          :type="image?.fallback?.[0]?.mimeType"
        />
        <NuxtImg
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          class="inset-0 h-[200px] w-full object-cover object-center md:absolute md:h-full"
          :src="image?.image?.[0]?.url"
          :alt="heroImageAlt"
        />
      </template>
    </picture>

    <div class="m-auto max-w-6xl bg-white px-4 md:bg-transparent md:px-0">
      <div class="relative grid gap-6 md:max-w-[70%] md:pr-4 lg:max-w-[50%]">
        <h1
          class="mt-6 text-center text-3xl text-supporting-black-87 antialiased md:mt-0 md:text-left md:text-[64px] md:leading-[66px] md:text-white lg:max-w-[500px]"
        >
          {{ block.heading }}
        </h1>
        <div
          class="hidden text-lg text-white antialiased md:block md:max-w-[470px] md:text-lg"
          v-html="block.contentDesktop"
        ></div>
        <div
          class="text-center antialiased md:hidden [&>*]:text-lg [&>*]:text-supporting-black-87"
          v-html="block.contentMobile"
        ></div>
        <div class="grid gap-6 md:items-center md:pr-4 lg:grid-cols-[1fr_1fr]">
          <template v-for="childBlock in block.children" :key="childBlock.id">
            <template v-if="childBlock.typeHandle === 'BlockBookButtonLarge'">
              <EditorialButtonLink
                class="flex md:inline-flex"
                :url="childBlock.redirectUrl"
                :inline-svg="childBlock.icon.inlineSvg"
              >
                {{ childBlock?.text }}
              </EditorialButtonLink>
            </template>
          </template>
          <ClientOnly
            ><NextAvailableTime
              v-if="block?.showNextAvailTime"
              :time-slot-delay="globals?.timeSlotDelay?.number || 10"
            ></NextAvailableTime
          ></ClientOnly>
        </div>
      </div>
    </div>
  </section>
  <section class="curved-top px-4 py-6 md:px-6 md:py-12">
    <div class="mx-auto max-w-6xl">
      <h2
        class="pb-6 pt-3 text-center text-2xl text-dynamic-primary md:pb-9 md:pt-0 md:text-3xl"
      >
        Hur kan vi hjälpa dig idag?
      </h2>

      <ul
        class="grid grid-cols-1 overflow-hidden rounded-lg border md:grid-cols-2 md:gap-4 md:overflow-visible md:rounded-none md:border-0"
      >
        <li>
          <NuxtLink
            to="https://account.firstvet.com/se?direct_auth=true"
            class="flex items-center gap-3 border-b bg-white p-4 pb-3 pt-5 text-supporting-black-87 md:rounded-lg md:border-0 md:px-4 md:py-5 md:shadow-md md:hover:z-10 md:hover:shadow-lg"
            @click="triggerEvent('clicked_service_selection_vet_help')"
          >
            <StaticPictogram name="service-list-vet" class="" size="2xl" />
            Få hjälp av veterinär direkt
            <StaticPictogram name="chevron-right" class="ml-auto" size="lg" />
          </NuxtLink>
        </li>

        <li>
          <NuxtLink
            to="/sv/butik"
            class="flex items-center gap-3 border-b bg-white px-4 py-3 text-supporting-black-87 md:rounded-lg md:border-0 md:px-4 md:py-5 md:shadow-md md:hover:z-10 md:hover:shadow-lg"
            @click="triggerEvent('clicked_service_selection_shop')"
          >
            <StaticPictogram name="service-list-supply" class="" size="2xl" />

            Handla djurprodukter
            <StaticPictogram name="chevron-right" class="ml-auto" size="lg" />
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="/sv/butik/mina-sidor/recept"
            class="flex items-center gap-3 border-b bg-white px-4 py-3 text-supporting-black-87 md:rounded-lg md:border-0 md:px-4 md:py-5 md:shadow-md md:hover:z-10 md:hover:shadow-lg"
            rel="nofollow"
            @click="triggerEvent('clicked_service_selection_prescription')"
          >
            <StaticPictogram name="service-list-pills" class="" size="2xl" />
            Hämta ut dina djurrecept
            <StaticPictogram name="chevron-right" class="ml-auto" size="lg" />
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="https://firstvet.page.link/bokafastingsamtal"
            :external="true"
            target="_blank"
            rel="noopener noreferrer"
            class="flex items-center gap-3 bg-white px-4 pb-5 pt-3 text-supporting-black-87 md:rounded-lg md:border-0 md:px-4 md:py-5 md:shadow-md md:hover:z-10 md:hover:shadow-lg"
            @click="triggerEvent('clicked_service_selection_tick')"
          >
            <StaticPictogram name="service-list-flee" class="" size="2xl" />
            Få recept på fästingmedel
            <StaticPictogram name="chevron-right" class="ml-auto" size="lg" />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </section>
  <div class="bg-supporting-dark-1 text-white">
    <div class="mx-auto max-w-6xl overflow-x-auto">
      <ul class="flex gap-8 p-4 md:justify-center md:px-0 md:py-6">
        <li class="flex gap-3 whitespace-nowrap">
          <DynamicPictogram name="check-mark-alt" size="lg" class="shrink-0" />
          100% legitimerade veterinärer
        </li>
        <li class="flex gap-3 whitespace-nowrap">
          <DynamicPictogram name="calendar" size="lg" />
          Tillgängliga 7 dagar i veckan
        </li>
        <li class="flex gap-3 whitespace-nowrap">
          <DynamicPictogram name="start-outline" size="lg" />
          4.9 Betyg i App store
        </li>
        <li class="flex gap-3 whitespace-nowrap pr-6">
          <DynamicPictogram name="paw-outline" size="lg" />
          98% nöjda mattar och hussar
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const gtm = useGtm();

const heroImageAlt = computed(
  () =>
    props?.block?.responsiveHeroImage
      ?.filter((item: any) => item.typeHandle === 'BlockImageAlt')
      ?.map((item: any) => item.text)
      ?.pop() || ''
);

const triggerEvent = (name: string) => {
  gtm?.trackEvent({
    event: name,
    action: 'click',
    noninteraction: false,
  });
};
</script>

<style>
@media screen and (max-width: 768px) {
  .curved-top {
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 389"  fill="none"><path d="M0 35.767s72.257-23.851 196.975-32.1c124.717-8.25 192.956 0 192.956 0v384.575L0 387.311V35.767Z" fill="rgba(248, 236, 222, 1)"/></svg>'),
      linear-gradient(
        0deg,
        rgb(255, 255, 255) 0%,
        rgb(255, 255, 255) 80%,
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0) 100%
      );
  }
}
</style>
